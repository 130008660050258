import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SelectContainer } from 'aligentreact';

class QuoteItem extends Component {
    renderPrice({ value, modifier, onSale = false }) {
        if (!value) {
            return null;
        }

        const classNames = classnames(
            'item-price',
            { [`item-price--${modifier}`]: modifier },
            { [`item-price__onSale`]: onSale }
        );

        return (
            <div className={classNames}>
                <span className="quote-item__label">{value}</span>
            </div>
        );
    }

    handleConfigurableChange = (e, configurableKey, silent = false) => {
        const {
            target: { value }
        } = e;
        const { updateConfigurable } = this.props;
        updateConfigurable({ key: configurableKey, value, silent });
    };

    renderConfigurable(configurableData) {
        const {
            id: configurableKey,
            label,
            labelOnly,
            selection,
            options
        } = configurableData;
        const { updateConfigurable, item } = this.props;
        const showOptions =
            !labelOnly && updateConfigurable && options && options.length > 1;

        return (
            <div
                className={classnames(
                    `quote-item__option`,
                    `quote-item__option--${configurableKey}`
                )}
                key={configurableKey}
            >
                {showOptions && !item.hideConfigurables && (
                    <SelectContainer
                        id={`select-${configurableKey}__${item.sku}`}
                        name={label}
                        label={label}
                        options={options}
                        value={selection}
                        isValid={true}
                        handleChange={(e) =>
                            this.handleConfigurableChange(e, configurableKey)
                        }
                    />
                )}
            </div>
        );
    }

    renderConfigurables() {
        const { item } = this.props;

        const configurables = Object.keys(item.configurables);
        return configurables.map((key) =>
            this.renderConfigurable(item.configurables[key])
        );
    }

    renderFreeGiftItemQuantity() {
        const { item } = this.props;
        const qty = item.configurables.qty.selection;

        return <span className="free-gift-qty">Qty: {qty}</span>;
    }

    renderErrorMessage() {
        const { item } = this.props;
        if (!item.errorMessage || item.errorMessage === '') {
            return null;
        }

        return (
            <div className="quote-item__error-message">{item.errorMessage}</div>
        );
    }

    renderMessage() {
        const { item } = this.props;
        return item.message ? (
            <div className="quote-item__message">{item.message}</div>
        ) : null;
    }

    render() {
        const { item, title, subtitle, actions } = this.props;

        return (
            <section className="quote-item">
                <div className="quote-item__image-wrapper">
                    <a href={item.uri} className="quote-item__image-link">
                        <img
                            src={item.image.src}
                            alt={item.image.alt}
                            title={item.image.alt}
                            className="quote-item__image"
                        />
                    </a>
                </div>
                <div className="quote-item__details">
                    <h3 className="quote-item__title">
                        <a className="quote-item__link" href={item.uri}>
                            {title || item.name}
                        </a>
                        {subtitle && (
                            <div className="quote-item__subtitle">
                                {subtitle}
                            </div>
                        )}
                    </h3>
                    <div className="quote-item__attributes">
                        {item.attributes.map((attr, index, attributes) => (
                            <span
                                key={attr.label}
                                className="quote-item__attribute"
                            >
                                {attr.value}
                                {index + 1 === attributes.length ? '' : ', '}
                            </span>
                        ))}
                    </div>
                    {this.renderErrorMessage()}

                    <div className="quote-item__price">
                        {this.renderPrice({
                            value: item.price,
                            modifier: 'current',
                            onSale: item.onSale
                        })}
                        {item.onSale &&
                            this.renderPrice({
                                value: item.priceSale,
                                modifier: 'sale'
                            })}
                        {item.isFreeGiftItem &&
                            this.renderFreeGiftItemQuantity()}
                    </div>

                    {item.headline && (
                        <div className="quote-item__headline">
                            {item.headline}
                        </div>
                    )}

                    <div className="quote-item__configurables">
                        {this.renderConfigurables()}
                    </div>
                    {this.renderMessage()}
                </div>

                {actions && (
                    <div className="quote-item__actions">{actions}</div>
                )}
            </section>
        );
    }
}

QuoteItem.propTypes = {
    item: PropTypes.object.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    updateConfigurable: PropTypes.func,
    actions: PropTypes.element
};

export default QuoteItem;
