import React, { Fragment } from 'react';
import 'what-input';

import Trigger from '@miniCart/components/Trigger';
import Header from '@miniCart/components/Header';
import Items from '@miniCart/components/Items';
import UpsellProducts from '@miniCart/components/UpsellProducts';
import { useMiniCart } from './talons/useMiniCart';
import { FreeShippingIndicator } from '@components';

const MiniCart = () => {
    const {
        freeShipping,
        isOpen,
        itemCount,
        itemsErrorCount,
        handleScreenClick
    } = useMiniCart();

    return (
        <div className="minicart">
            <Trigger />
            {isOpen && (
                <Fragment>
                    <div className="minicart-content">
                        <Header itemCount={itemCount} />
                        <Items />
                        <FreeShippingIndicator
                            isActive={freeShipping.isActive}
                            isAvailable={freeShipping.isAvailable}
                            remaining={freeShipping.remaining}
                            percentage={freeShipping.percentage}
                            disclaimer={freeShipping.disclaimer}
                        />
                        {itemCount > 0 && itemsErrorCount === 0 && (
                            <a className="cart-checkout" href="/checkout">
                                Proceed to checkout
                            </a>
                        )}
                        <UpsellProducts />
                    </div>
                    <div className="screen" onClick={handleScreenClick}>
                        {/* listens to click to close minicart */}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default MiniCart;
