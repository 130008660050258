import 'core-js/stable';
import 'regenerator-runtime/runtime';
import {
    createHttpLink,
    ApolloClient,
    ApolloProvider,
    InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import MiniCart from '@miniCart/components/MiniCart';
import MiniCartContextProvider from '@miniCart/context';
import store from '@miniCart/store/store';

const minicartContainer = document.querySelector('[data-react-minicart]');

const httpLink = createHttpLink({
    uri: '/graphql'
});

if (minicartContainer) {
    const configuration = JSON.parse(minicartContainer.dataset.config);

    const storeLink = setContext((_, { headers }) => {
        const { storeCode } = configuration;

        return {
            headers: {
                ...headers,
                store: storeCode
            }
        };
    });

    const client = new ApolloClient({
        link: storeLink.concat(httpLink),
        uri: configuration.baseUrl + 'graphql',
        cache: new InMemoryCache()
    });

    ReactDOM.render(
        <ApolloProvider client={client}>
            <Provider store={store}>
                <MiniCartContextProvider>
                    <MiniCart />
                </MiniCartContextProvider>
            </Provider>
        </ApolloProvider>,
        minicartContainer
    );
}
