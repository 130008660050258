import React from 'react';
import { bool, number, string } from 'prop-types';
import { formatPrice } from '@aligent/utils';

const FreeShippingIndicator = ({
    isActive,
    isAvailable,
    remaining,
    percentage,
    disclaimer
}) => {
    if (!isActive || percentage === 0) {
        return null;
    }

    return (
        <div className="free-shipping-indicator">
            <div className="progress-label">
                {isAvailable ? (
                    <span>You have qualified for free shipping</span>
                ) : (
                    <span>
                        You are{' '}
                        <span className="amount-remaining">
                            {formatPrice(remaining)}
                        </span>{' '}
                        away from free shipping
                    </span>
                )}
            </div>
            <div className="meter">
                <div
                    className="meter-complete"
                    style={{ width: `${percentage}%` }}
                >
                    {/* Filled with css */}
                </div>
            </div>
            {disclaimer && <div className="disclaimer">{disclaimer}</div>}
        </div>
    );
};

FreeShippingIndicator.propTypes = {
    disclaimer: string,
    isActive: bool,
    isAvailable: bool,
    remaining: string,
    percentage: number
};

export default FreeShippingIndicator;
