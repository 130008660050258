import { handleActions } from 'redux-actions';

import actions from './actions';

export const initialState = {
    cartId: '',
    errorMessage: '',
    isOpen: false,
    isLoading: false,
    items: [],
    freeShipping: {},
    subtotal: null,
    upsellProducts: []
};

const reducerMap = {
    [actions.toggleIsOpen]: (state) => {
        return {
            ...state,
            isOpen: !state.isOpen
        };
    },
    [actions.setFreeShippingIndicatorValues]: (state, { payload }) => {
        return {
            ...state,
            freeShipping: {
                isActive: payload.free_shipping_active,
                isAvailable: payload.free_shipping_available,
                remaining: payload.free_shipping_remaining,
                percentage: payload.free_shipping_percentage,
                disclaimer: payload.free_shipping_disclaimer
            }
        };
    },
    [actions.setIsLoading]: (state, { payload }) => {
        return {
            ...state,
            errorMessage: '',
            isLoading: payload
        };
    },
    [actions.setError]: (state, { payload }) => {
        return {
            ...state,
            errorMessage: payload,
            isLoading: false
        };
    },
    [actions.setCart]: (state, { payload }) => {
        const cartId = payload.id || state.cartId;
        const items = payload.items || state.items;
        const upsellProducts =
            payload.upsellProducts?.items || state.upsellProducts;

        return {
            ...state,
            cartId,
            errorMessage: '',
            isLoading: false,
            items,
            upsellProducts
        };
    },
    [actions.setCartId]: (state, { payload }) => {
        return {
            ...state,
            cartId: payload
        };
    },
    [actions.setSubtotal]: (state, { payload }) => {
        return {
            ...state,
            subtotal: payload
        };
    }
};

export default handleActions(reducerMap, initialState);
