import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckboxRadio, InputContainer } from 'aligentreact';
import classnames from 'classnames';

import { inputField } from '@src/prop-types';

export const PersonalDetails = ({
    company,
    firstname,
    handleBlur,
    idPrefix,
    lastname,
    telephone,
    updateField
}) => {
    const initialShowCompany = company.value !== '';
    const [showCompany, setShowCompany] = useState(initialShowCompany);

    const handleChange = (event) => {
        const { name, value } = event.target;
        updateField(name, value);
    };

    const handleToggleCompanyField = (event) => {
        const { checked } = event.target;
        setShowCompany(checked);

        if (!checked) {
            updateField('company', '');
        }
    };

    const handleCompanyFieldChange = (event) => {
        handleChange(event);
        if (!showCompany) {
            setShowCompany(true);
        }
    };

    return (
        <div className="address">
            <InputContainer
                id={`${idPrefix}-address-first-name`}
                label={firstname.label}
                name="firstname"
                value={firstname.value}
                isValid={firstname.valid}
                isRequired={firstname.isRequired}
                errorMessage={firstname.errorMessage}
                onChange={handleChange}
                placeholder={firstname.placeholder}
                onBlur={handleBlur}
            />
            <InputContainer
                id={`${idPrefix}-address-last-name`}
                label={lastname.label}
                name="lastname"
                value={lastname.value}
                isValid={lastname.valid}
                isRequired={lastname.isRequired}
                errorMessage={lastname.errorMessage}
                onChange={handleChange}
                placeholder={lastname.placeholder}
                onBlur={handleBlur}
            />
            <CheckboxRadio
                type="checkbox"
                id={`${idPrefix}-company-name-toggle`}
                name={`${idPrefix}-company-name-toggle`}
                label={
                    idPrefix === 'delivery'
                        ? 'Delivering to a workplace or business?'
                        : 'Show Company'
                }
                onChange={handleToggleCompanyField}
                isSelected={showCompany}
            />
            <div
                className={classnames('company-container', {
                    'company-container--open': showCompany
                })}
            >
                <InputContainer
                    id={`${idPrefix}-address-company`}
                    label={company.label}
                    name="company"
                    value={company.value}
                    isValid={company.valid}
                    isRequired={company.isRequired}
                    errorMessage={company.errorMessage}
                    onChange={handleCompanyFieldChange}
                    placeholder={company.placeholder}
                    onBlur={handleBlur}
                />
            </div>
            <InputContainer
                id={`${idPrefix}-address-telephone`}
                label={telephone.label}
                name="telephone"
                value={telephone.value}
                isValid={telephone.valid}
                isRequired={telephone.isRequired}
                errorMessage={telephone.errorMessage}
                onChange={handleChange}
                type="tel"
                placeholder={telephone.placeholder}
                onBlur={handleBlur}
            />
        </div>
    );
};

PersonalDetails.propTypes = {
    updateField: PropTypes.func.isRequired,
    idPrefix: PropTypes.string.isRequired,
    firstname: inputField,
    lastname: inputField,
    company: inputField,
    telephone: inputField,
    handleBlur: PropTypes.func
};

export default PersonalDetails;
