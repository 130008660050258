import React from 'react';
import PropTypes from 'prop-types';

const QuoteItemAction = ({
    classNames,
    action,
    disabled,
    title,
    icon,
    label
}) => (
    <button
        className={`quote-item__action ${classNames}`}
        {...disabled}
        onClick={action}
        title={title}
        type="button"
    >
        {label && <span className="quote-item__action-label">{label}</span>}
        {icon && <span className="quote-item__action-icon">{icon}</span>}
    </button>
);

QuoteItemAction.propTypes = {
    classNames: PropTypes.string,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.string
};

QuoteItemAction.defaultProps = {
    disabled: false
};

export default QuoteItemAction;
