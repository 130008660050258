import React from 'react';
import Slider from 'react-slick';
import { useMiniCartContext } from '@miniCart/context';
import UpsellProduct from './UpsellProduct';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SLIDER_SETTINGS = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2
};

const UpsellProducts = () => {
    const [{ cartId, upsellProducts }, { actions }] = useMiniCartContext();

    return upsellProducts.length > 0 ? (
        <div className="upsell-products mf-carousel">
            <h4 className="upsell-products__heading">Customers Also Bought</h4>
            <Slider className="upsell-products__slider" {...SLIDER_SETTINGS}>
                {upsellProducts.map((product) => (
                    <UpsellProduct
                        key={product.id}
                        cartId={cartId}
                        product={product}
                        updateCart={actions.setCart}
                    />
                ))}
            </Slider>
        </div>
    ) : null;
};

export default UpsellProducts;
