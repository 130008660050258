import React, { useEffect } from 'react';
import { formatPrice } from '@aligent/utils';
import { useMutation } from '@apollo/client';
import { useMiniCartContext } from '@miniCart/context';
import UPDATE_ITEM_CART from '@miniCart/graphql/updateItemInCart.graphql';
import REMOVE_ITEM_CART from '@miniCart/graphql/removeCartItem.graphql';

export const useItems = () => {
    const [{ items, cartId, errorMessage }, { actions }] = useMiniCartContext();
    const [updateItemMinicart, { loading, data, error }] = useMutation(
        UPDATE_ITEM_CART
    );

    const [
        removeItemMinicart,
        {
            loading: removeMutationLoading,
            data: removeMutationData,
            error: removeMutationError
        }
    ] = useMutation(REMOVE_ITEM_CART);

    useEffect(() => {
        if (loading) {
            actions.setIsLoading(loading);
            return;
        }

        if (error) {
            actions.setError(error.message);
            return;
        }

        if (data) {
            actions.setCart(data.updateCartItems.cart);
        }
    }, [loading, data, error]);

    const updateProductQuantity = (event, itemId) => {
        const quantity = event.value;
        updateItemMinicart({ variables: { cartId, itemId, quantity } });
    };

    useEffect(() => {
        if (removeMutationLoading) {
            actions.setIsLoading(removeMutationLoading);
            return;
        }

        if (removeMutationError) {
            actions.setError(removeMutationError.message);
            return;
        }

        if (removeMutationData) {
            actions.setCart(removeMutationData.removeItemFromCart.cart);
        }
    }, [removeMutationLoading, removeMutationError, removeMutationData]);

    const removeCartItem = (itemId) => {
        removeItemMinicart({
            variables: {
                cartId,
                itemId
            }
        });
    };

    const createQuoteItem = (item) => {
        const rowTotal = item.prices.row_total_including_tax.value;
        const quantity = item.quantity;
        const DEFAULT_MAX = 15;
        const MAXIMUM_INCREASE = 2;
        const itemError = item.errors?.[0].message;
        const maxOption = Math.max(
            item.quantity + MAXIMUM_INCREASE,
            DEFAULT_MAX
        );
        const numberOptions = [];
        for (let i = 1; i < maxOption + 1; i++) {
            numberOptions.push({
                label: i.toString(),
                value: i
            });
        }

        const qty = {
            id: 'qty',
            label: 'Qty',
            selection: item.quantity,
            labelOnly: item.is_virtual === '1',
            options: numberOptions
        };

        return {
            attributes: [],
            configurables: { qty },
            image: {
                alt: item.product.name,
                src: item.product.small_image.url
            },
            name: item.product.name,
            onSale: false,
            price: formatPrice(rowTotal / quantity),
            priceSale: formatPrice(rowTotal / quantity),
            sku: item.product.sku,
            uri: `/${item.product.url_key}.html`,
            id: item.id,
            errorMessage: itemError
        };
    };

    const products = items.map((item) => createQuoteItem(item));

    return {
        products,
        updateProductQuantity,
        removeCartItem,
        errorMessage
    };
};
