import PropTypes from 'prop-types';

import { inputField } from './';

export default PropTypes.shape({
    firstname: inputField,
    lastname: inputField,
    email: inputField,
    telephone: inputField,
    company: inputField
});
