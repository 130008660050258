import PropTypes from 'prop-types';

import { inputField } from './';

export default PropTypes.shape({
    street: inputField,
    city: inputField,
    postcode: inputField,
    region: inputField,
    singleLine: PropTypes.shape({
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string
    }),
    atl_signature: PropTypes.bool.isRequired,
    atl_instructions: PropTypes.string.isRequired,
    atl_valid: PropTypes.bool.isRequired
});
