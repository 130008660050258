import { formatPrice } from '@aligent/utils';
import { useMiniCartContext } from '@miniCart/context';

export const useHeader = () => {
    const [{ subtotal }, { actions }] = useMiniCartContext();

    const handleCloseButtonClick = () => {
        actions.toggleIsOpen();
    };

    return {
        totalPrice: subtotal ? formatPrice(subtotal) : '',
        handleCloseButtonClick
    };
};
