import { createActions } from 'redux-actions';

const actionTypes = [
    'SET_CART',
    'SET_FREE_SHIPPING_INDICATOR_VALUES',
    'SET_CART_ID',
    'SET_ERROR',
    'SET_IS_LOADING',
    'SET_SUBTOTAL',
    'TOGGLE_IS_OPEN'
];

export default createActions(...actionTypes);
