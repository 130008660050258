import PropTypes from 'prop-types';

export default PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    valid: PropTypes.bool,
    disabled: PropTypes.bool
});
