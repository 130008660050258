import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

export default class RewardSlider extends PureComponent {
    render() {
        return <Slider {...this.props} />;
    }
}

RewardSlider.defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    value: 1,
    onChange: () => {}
};

RewardSlider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func
};
