import React from 'react';
import PropTypes from 'prop-types';
import { useHeader } from './talons/useHeader';

const Header = (props) => {
    const { itemCount } = props;
    const { totalPrice, handleCloseButtonClick } = useHeader();

    return (
        <div className="header">
            <div className="summary">
                <div className="summary__count">{itemCount}</div>
                <div className="summary__text">
                    {itemCount === 1 ? 'ITEM IN BAG' : 'ITEMS IN BAG'}
                </div>
                <div className="summary__total">{totalPrice}</div>
            </div>
            <button
                className="close-button"
                title="Close"
                onClick={handleCloseButtonClick}
            >
                <span className="close-button__text">Close Mini Cart</span>
                <div className="close-button__icon">{/* Filled by CSS */}</div>
            </button>
        </div>
    );
};

Header.propTypes = {
    itemCount: PropTypes.number
};

export default Header;
