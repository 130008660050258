import React, { createContext, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import bindActionCreators from '@utils/bindActionsCreators';

import actions from '@miniCart/store/actions';

const MiniCartContext = createContext();

const MiniCartContextProvider = (props) => {
    const { actions, miniCartState, children } = props;

    const cartApi = useMemo(() => ({ actions }), [actions]);

    const contextValue = useMemo(() => [miniCartState, cartApi], [
        cartApi,
        miniCartState
    ]);

    return (
        <MiniCartContext.Provider value={contextValue}>
            {children}
        </MiniCartContext.Provider>
    );
};

const mapStateToProps = (state) => ({ miniCartState: state });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MiniCartContextProvider);

export const useMiniCartContext = () => useContext(MiniCartContext);
