import React, { Fragment } from 'react';
import QuoteItemAction from '@containers/QuoteItems/QuoteItemAction';
import QuoteItem from '@containers/QuoteItems/QuoteItem';

import { useItems } from './talons/useItems';

const Items = () => {
    const {
        products,
        updateProductQuantity,
        removeCartItem,
        errorMessage
    } = useItems();

    if (!errorMessage && products.length === 0) {
        return null;
    }

    return (
        <div className="minicart-items">
            {products.map((product) => (
                <QuoteItem
                    item={product}
                    itemId={product.id}
                    key={product.id}
                    updateConfigurable={(event) =>
                        updateProductQuantity(event, product.id)
                    }
                    errorMessage={product.errorMessage}
                    actions={
                        <Fragment>
                            <QuoteItemAction
                                classNames="quote-item__action--remove"
                                title="Remove item from your bag"
                                icon={
                                    <Fragment>
                                        <span className="label-text">
                                            Remove
                                        </span>
                                        <span className="remove-icon">
                                            {/* Filled in css */}
                                        </span>
                                    </Fragment>
                                }
                                action={() => removeCartItem(product.id)}
                            />
                        </Fragment>
                    }
                />
            ))}
            <div className="error-message">{errorMessage}</div>
        </div>
    );
};

export default Items;
