import { useMemo } from 'react';
import { useMiniCartContext } from '@miniCart/context';

export const useMiniCart = () => {
    const [{ isOpen, items, freeShipping }, { actions }] = useMiniCartContext();

    const itemCount = useMemo(
        () => items.reduce((sum, item) => sum + item.quantity, 0),
        [items]
    );

    const itemsErrorCount = items.reduce(
        (sum, item) => sum + (item.errors?.length || 0),
        0
    );

    const handleScreenClick = () => {
        actions.toggleIsOpen();
    };

    return {
        handleScreenClick,
        freeShipping,
        isOpen,
        itemCount,
        itemsErrorCount
    };
};
