import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function getLabel(label, modifier, handleRemove, id) {
    if (!label) return null;
    const remove = modifier ? (
        <button
            className="remove-modifier"
            onClick={handleRemove}
            data-index={id}
        >
            X
        </button>
    ) : null;
    return (
        <span>
            {label}
            {remove}
        </span>
    );
}

const PriceDisplay = ({
    id,
    className,
    label,
    subLabel,
    price,
    discount,
    modifier,
    handleRemove
}) => {
    const subLabelJSX = subLabel ? <span>{subLabel}</span> : null;
    const classNames = classnames('price-display', `price--${id}`, className, {
        discount
    });
    return (
        <div className={classNames}>
            <div className="price-display__label">
                {getLabel(label, modifier, handleRemove, id)}
                {subLabelJSX}
            </div>
            <div className="price-display__price">{price}</div>
        </div>
    );
};

PriceDisplay.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired
    ]),
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.element.isRequired,
        PropTypes.object.isRequired
    ]),
    price: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired
    ]),
    handleRemove: PropTypes.func,
    className: PropTypes.string,
    subLabel: PropTypes.string,
    discount: PropTypes.bool,
    modifier: PropTypes.bool
};

export default PriceDisplay;
