import React, { useCallback } from 'react';
import PropType from 'prop-types';
import classnames from 'classnames';
import { useMutation } from '@apollo/client';
import ADD_PRODUCTS_TO_CART from '@miniCart/graphql/addProductsToCart.graphql';
import ProductPrice from './ProductPrice';

const UpsellProduct = (props) => {
    const { cartId, onAddToCartButtonClick, product, updateCart } = props;

    const [addProductsToCart, { loading }] = useMutation(ADD_PRODUCTS_TO_CART, {
        onCompleted: (data) => updateCart(data.addProductsToCart.cart)
    });

    const handleAddToCart = useCallback(() => {
        if (onAddToCartButtonClick) {
            onAddToCartButtonClick();
        }

        addProductsToCart({
            variables: {
                cartId,
                cartItems: [
                    {
                        sku: product.sku,
                        quantity: 1
                    }
                ]
            }
        });
    }, [addProductsToCart, onAddToCartButtonClick]);

    return (
        <div className="upsell-products__item">
            <div className="upsell-products__item__image-wrapper">
                <a
                    className="upsell-products__item__image-link"
                    href={`/${product.url_key}.html`}
                >
                    <img
                        className="upsell-products__item__image"
                        src={product.small_image.url}
                        alt={product.small_image.label || product.name}
                    />
                </a>
            </div>
            <div className="upsell-products__item__details">
                <h4 className="upsell-products__item__name">
                    <a
                        className="upsell-products__item__name-link"
                        href={`/${product.url_key}.html`}
                    >
                        {product.name}
                    </a>
                </h4>
                <ProductPrice
                    baseClassName="upsell-product"
                    product={product}
                />
            </div>
            <button
                className={classnames('upsell-products__add-to-cart-button', {
                    loading
                })}
                aria-label="Add to cart"
                onClick={handleAddToCart}
            >
                {loading && 'Adding...'}
            </button>
        </div>
    );
};

UpsellProduct.propTypes = {
    cartId: PropType.string,
    onAddToCartButtonClick: PropType.func,
    product: PropType.object,
    updateCart: PropType.func
};

export default UpsellProduct;
