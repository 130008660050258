import { createStore } from 'redux';

import miniCartReducer from './reducer';

const store = createStore(
    miniCartReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
