import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getProductPrices } from '@utils';

const Price = (props) => {
    const { baseClassName, value, modifier, onSale = false } = props;

    if (!value) {
        return null;
    }

    const classNames = classnames(
        'item-price',
        { [`item-price--${modifier}`]: modifier },
        { 'item-price__onSale': onSale }
    );

    return (
        <div className={classNames}>
            <span className={`${baseClassName}__label`}>{value}</span>
        </div>
    );
};

Price.propTypes = {
    baseClassName: PropTypes.string,
    modifier: PropTypes.oneOf(['current', 'sale']),
    onSale: PropTypes.bool,
    value: PropTypes.number
};

const ProductPrice = (props) => {
    const { baseClassName, product } = props;

    const { regularPrice, salePrice, onSale } = getProductPrices(product);

    return (
        <div className={`${baseClassName}__price`}>
            <Price
                baseClassName={baseClassName}
                value={regularPrice}
                modifier="current"
                onSale={onSale}
            />
            {onSale && <Price value={salePrice} modifier="sale" />}
        </div>
    );
};

ProductPrice.propTypes = {
    baseClassName: PropTypes.string,
    product: PropTypes.object
};

export default ProductPrice;
