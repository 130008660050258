import { formatPrice } from '@aligent/utils';

/**
 * Get the regular and sale price of products from GraphQL responses, using `price_range`.
 * This is another version of `getProductPrices` from `@aligent/utils`.
 *
 * @param {object} item The item to get the prices for
 * @param {string} priceFormat the prices' format
 * @returns {object}
 */
export default (item, priceFormat) => {
    const regularPrice = item.price_range.minimum_price.regular_price?.value;
    const salePrice = item.price_range.minimum_price.final_price?.value;
    const onSale = salePrice < regularPrice;

    return {
        regularPrice: formatPrice(regularPrice, priceFormat, true),
        salePrice: formatPrice(salePrice, priceFormat, true),
        onSale
    };
};
