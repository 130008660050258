import React from 'react';

import { useTrigger } from './talons/useTrigger';

const Trigger = () => {
    const { quantity, toggleMiniCart } = useTrigger();

    return (
        <button className="cart-trigger" onClick={toggleMiniCart}>
            <span className="text">My Cart</span>
            {/* Shopping cart icon added in css */}
            {quantity > 0 && <span className="counter">{quantity}</span>}
        </button>
    );
};

export default Trigger;
